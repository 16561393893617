import React, { useMemo, useCallback } from 'react';
import StyledAdCard from './AdCard.style';
import Button from './global/Button';
import Heading from './global/Heading';
import Typography from './global/Typography';
import StructuredData from './StructuredData';
import { withTheme } from '@emotion/react';
import capitalize from '../app/helpers/capitalize';
import currency, { currenyNoDecimals } from '../app/helpers/currency';
import {
  ad as adUrl,
  getAdUrlParts,
  getSearchParamFilter,
} from '../app/helpers/url';
import OptimizedImage from './global/OptimizedImage';
import isEmbedded from '../app/helpers/isEmbedded';
import { openAdsInNewTab } from '../app/helpers/isEmbedded';
import Badge from './Badge';
import {
  DateFormat,
  getMainProperties,
  getTitle,
  MileageFormat,
} from '../app/helpers/adCardContent';

import ParamWhitelistFilter from '../app/helpers/paramWhitelistFilter';
import {DateTime} from "luxon";


const Placeholder = withTheme(({ theme }) => (
  <svg className="img" viewBox="-60 -60 240 240">
    <g opacity={0.3}>
      <path
        d="M21.9615 38.0384C9.83251 59.0464 17.0304 85.9094 38.0385 98.0384C59.0465 110.167 85.9095 102.969 98.0385 81.9614"
        stroke="#1E4275"
        fill="none"
        strokeWidth="11.8254"
      />
      <path
        d="M98.0385 81.9616C110.167 60.9536 102.97 34.0906 81.9615 21.9616C60.9534 9.83261 34.0905 17.0305 21.9615 38.0386"
        stroke="#009EE3"
        fill="none"
        strokeWidth="11.8254"
      />
      <path
        d="M62.7581 69.6535C61.9537 69.1938 60.1149 68.2745 61.0343 66.6656C61.9537 64.9417 64.0223 66.6656 64.0223 66.6656C78.9621 75.0548 88.6155 72.0669 92.293 70.1132C92.8676 68.1595 93.3273 66.0909 93.5571 63.9074C74.71 69.7684 60.5746 53.6794 60.5746 53.6794C60.5746 53.6794 56.3225 58.8509 48.8526 58.5061C41.3827 58.1614 36.7858 51.2661 36.7858 51.2661C36.7858 51.2661 48.5078 41.1529 55.1733 34.6024C59.5403 30.3503 66.4356 28.6265 70.6877 27.937C67.355 26.7877 63.6775 26.2131 60 26.2131C41.3827 26.2131 26.213 41.3828 26.213 60.0001C26.213 78.6174 41.3827 93.7871 60 93.7871C62.988 93.7871 66.7804 93.3274 69.5385 92.5229C59.8851 91.6036 48.6228 85.2829 48.6228 85.2829C48.6228 85.2829 45.5199 84.1336 46.6691 82.5247C47.7034 80.8009 49.772 82.5247 49.772 82.5247C58.9657 87.9261 71.3773 89.6499 75.2846 90.1096C77.9278 88.7305 80.4561 87.0067 82.6396 85.053C72.2966 86.6619 57.8165 79.192 55.8628 78.1577C53.9092 77.1234 52.1853 76.319 53.7942 74.4802C54.7136 73.4459 57.4717 75.2847 57.4717 75.2847C74.71 83.7889 83.9037 81.6054 87.0066 80.2263C88.3857 78.3876 89.6498 76.319 90.6841 74.1355C78.1576 78.2726 63.5626 69.9983 62.7581 69.6535Z"
        fill="#009EE3"
      />
      <path
        d="M57.7016 36.9012L41.7274 51.4962C49.4272 58.9661 57.1269 51.9559 57.1269 51.9559L66.4356 44.7158C66.4356 44.7158 67.4699 43.3368 68.734 44.2562C70.1131 45.2905 68.9639 46.7844 68.9639 46.7844L63.4476 51.7261C77.6979 64.9421 89.9945 61.4944 93.787 59.8855C93.787 49.4276 88.9603 40.119 81.6053 34.0281C67.5848 27.7074 57.7016 36.9012 57.7016 36.9012Z"
        fill="#1E4275"
      />
    </g>
  </svg>
));

const AdCard = ({ smallStyle, searchStyle, ad, sendEvent, lazy }, ref) => {
  const queryID = ad.__queryID;
  const adID = ad.id;

  const openInNewTab = openAdsInNewTab();
  const embedded = isEmbedded();
  const searchParamFilter = useCallback(
    getSearchParamFilter(embedded, openInNewTab),
    [embedded, openInNewTab]
  );

  const url = useMemo(
    () =>
      adUrl({
        ...getAdUrlParts({ ad }),
        search: window.location.search,
        searchParamFilter,
      }),
    [ad, searchParamFilter, window.location.search]
  );
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      sessionStorage.setItem('queryIdForAd', JSON.stringify({ queryID, adID }));
    } catch (e) {
      // Ignore
    }
    sendEvent?.('click', ad, 'Item clicked');

    if (openInNewTab || e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  };
  const sold = ad.status === 'S';
  const title = getTitle(ad);
  const brand = ad.make?.name || 'Onbekend merk';
  const model = ad.model?.name || 'Onbekend model';
  const modelDate = ad?.model_date ? (typeof ad.model_date === 'string'
      ? ad.model_date
      : DateTime.fromSeconds(ad.model_date).toISODate()
  ) : null;
  const registrationDate = ad?.registration_date ? (typeof ad.registration_date === 'string'
      ? ad.registration_date
      : DateTime.fromSeconds(ad.registration_date).toISODate()
  ) : null;
  const shortDescr = ((title, descr) => {
    if (descr?.toLowerCase().startsWith(title?.toLowerCase())) {
      return descr?.substring(title.length).trim();
    }
    return descr;
  })(`${title}`, `${ad.marketing_name}`);
  const mainPicture = ad.media?.filter((i) => i.mime_type == 'image/jpeg')?.[0]
    ?.original_url; // TODO: get srcset
  const mainProperties = getMainProperties(ad);
  const monthPriceEur =
    Number(`0${ad.default_monthly_payment}`?.replace(/[^0-9.]/, '')) || null;
  //const views = 0; //TODO: this should be loaded from somewhere

/** @type {Offer} */
  const offer = {
    '@type': 'Offer',
    availability: sold ? 'SoldOut' : 'InStock',
    priceCurrency: 'EUR',
    price: monthPriceEur,
    url,
    itemCondition: ad?.state,
    businessFunction: {
      '@type': 'BusinessFunction',
      '@id': 'http://purl.org/goodrelations/v1#LeaseOut',
    },
  };

  /** @type {Car} */
  const car = {
    '@context': 'https://schema.org',
    '@type': 'Car',
    url,
    name: title,
    description: shortDescr,
    brand: {
      '@type': 'Brand',
      name: brand,
    },
    offers: offer,
    model,
    vehicleModelDate: modelDate ?? null,
    manufacturer: {
      "@type": "Organization",
      name: ad?.make?.name ?? ad.marketing_name,
    },
    bodyType: ad.body_type,
    fuelType: ad.fuel_type,
    mileageFromOdometer: {
      "@type": "QuantitativeValue",
      value: ad.odometer,
      unitCode: ad.odometer_unit,
    },
    vehicleIdentificationNumber: ad?.vin,
    numberOfDoors: ad?.doors_count,
    seatingCapacity: ad?.seat_count,
    vehicleTransmission: ad?.transmission,
    emissionsCO2: ad?.co2_emission,
    color: ad?.body_color,
    dateVehicleFirstRegistered: registrationDate ?? null,
    image: mainPicture,
    ...Object.fromEntries(
      mainProperties.map(({ type, ...p }) => {
        switch (type) {
          case 'mileageFromOdometer': {
            /** @type {QuantitativeValue} */
            const q = {
              value: p.value,
              unitCode: p.unit,
            };
            return [type, q];
          }
        }
        if (p.value) {
          return [type, p.value];
        }
        return [type, p.text];
      })
    ),
  };



  const sizes = useMemo(
    () => [
      [null, 386],
      ['(max-width: 1280px)', 320],
      ['(max-width: 768px)', 738],
      ['(max-width: 500px)', 320],
    ],
    []
  );



  return (
    <StyledAdCard
      ref={ref}
      onClick={onClick}
      className={
        smallStyle ? 'small' : searchStyle ? `search ${searchStyle}` : ''
      }
    >
      <StructuredData data={car} />
      <div className="image-container">
        {!searchStyle && !smallStyle && <Badge ad={ad} />}
        {mainPicture ? (
          <OptimizedImage
            src={mainPicture}
            alt={`Foto van ${title} ${shortDescr}`}
            lazy={lazy}
            sizes={sizes}
          />
        ) : (
          <Placeholder />
        )}
      </div>
      <div className="details">
        <Heading className="name" variant="heading4">
          {title}
        </Heading>

        <Typography
          variant="controlStrong"
          color="bahamaBlue"
          className="description"
        >
          {shortDescr}
        </Typography>

        <Typography as="ul" variant="controlStrong" color="trout">
          {mainProperties.map(({ type, ...p }, ix) => {
            switch (type) {
              case 'mileageFromOdometer':
                return (
                  <li key={ix}>
                    <span>{MileageFormat.format(p.value)}</span>{' '}
                    <span>{p.unit === 'SMI' ? 'mi' : 'km'}</span>
                  </li>
                );
              case 'productionDate':
                return (
                  <li key={ix}>
                    {DateFormat.format(p.value).replace(...capitalize)}
                  </li>
                );
            }

            return <li key={ix}>{p.text}</li>;
          })}
        </Typography>
      </div>
      <div className="fill"></div>
      <div className="offer">
        <span className="price">
          {monthPriceEur && (
            <>
              <span>
                {searchStyle
                  ? currenyNoDecimals(monthPriceEur)
                  : currency(monthPriceEur)}
              </span>{' '}
              <span className="interval">P/M</span>
            </>
          )}
        </span>
        <Button arrow large variant="emerald" onClick={onClick} href={url}>
          {`Bekijk${smallStyle || searchStyle === 'desktop' ? '' : ' de deal'}`}
        </Button>
      </div>
    </StyledAdCard>
  );
};
export default React.forwardRef(AdCard);
